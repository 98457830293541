//----------- Necessary imports -------------//
import { checkDynamicComponents } from "./utils/dynamicComponentsStylesheet.js";

import "../../base/js/init.js";
import "../../base/js/utils/preCommonUtils.js";
import "../../../components/bhg/image.js";
import "../../base/js/utils/ajaxUtils.js";
import "../../base/js/utils/commonUtils.js";
import "../../base/js/shared/loading.js";
import "../../base/js/utils/customContextHubUtils.js";
import "../../base/js/utils/lastjs.js";

//----------- Fastbooking -------------//
import "../../../components/barcelo/structure/fastbooking/js/commonFastbookingProps.js";
import "../../../components/barcelo/structure/fastbooking/js/c-fastbooking";

const main = async function () {
  //----------- Hide footer until fully loaded -------------//
  const footer = document.querySelector("footer");
  if (footer) {
    footer.classList.add("dynamicLoading", "hiddenComponent");
  }

  //----------- Add loader to dynamic loading elements -------------//

  const loader = document.createElement("div");
  loader.classList.add("component-loader");
  loader.innerHTML = `<div class="loader-spinner"></div>`;

  // General loader
  const body = document.querySelector("body");
  body?.insertBefore(loader, body.firstChild);
  body?.classList.add("hiddenComponent");

  // Dynamic components loader
  document.querySelectorAll(".dynamicLoading.hiddenComponent").forEach(function (element) {
    const loader = document.createElement("div");
    loader.classList.add("component-loader");
    loader.innerHTML = `<div class="loader-spinner"></div>`;

    element.appendChild(loader);
  });

  // Remove general loader when all components are loaded
  bcl.u.removeFirstLoader();

  //----------- Control dynamic components -------------//
  checkDynamicComponents();

  //----------- Load RTL if necessary -------------//
  const detectedLanguages = ["ar-AE"];
  const currentLang = document.documentElement.lang;

  const loadRTL = function () {
    if (detectedLanguages.includes(document.documentElement.lang)) {
      bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-rtl.css");
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      observer.disconnect();
    }
  };

  const observer = new MutationObserver(loadRTL);

  if (detectedLanguages.includes(currentLang)) {
    loadRTL();
  } else {
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["lang"] });
  }
};

if (document.readyState !== "loading") {
  main();
} else {
  document.addEventListener("DOMContentLoaded", main, true);
}
