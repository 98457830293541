import { BarceloComponent } from "barcelo-components";

const AdvComponents = {
  ribbon: BarceloComponent({ selector: ".ribbon", path: "adv/ribbon/js/c-ribbon" }),
  languageRibbon: BarceloComponent({
    selector: ".ribbon-language",
    path: "adv/ribbon-language/js/c-ribbon-language",
    belongsTo: "ribbon",
    customName: "language",
  }),
  translationEnabledLanguageDialog: BarceloComponent({ selector: ".translation-enabled-language-dialog-JS", path: "adv/translation-enabled-language-dialog/js/translation-enabled-language-dialog" }),
};

export default AdvComponents;
