bcl.s.loading = {
  props: {
    dynamicClass: ".bhg-loading.dynamicLoading",
    cssClass: "c-loading-JS",
    defaultLoading: null,
    loader: null,
  },

  init(baseDom) {
    const baseFind = baseDom || document;
    const authorURLS = /localhost|author-barcelo-dev-65.adobecqms.net|author-barcelo-stage-65.adobecqms.net|author-barcelo-prod-65.adobecqms.net/;
    const loadingDataset = baseFind.body.dataset.loading;

    if (!loadingDataset) {
      console.log("No loading dataset found on body element");
      return;
    }

    const loadingURL = authorURLS.test(location.hostname) ? `${loadingDataset}?wcmmode=disabled` : loadingDataset;

    bcl.s.loading.props.defaultLoading = loadingURL;

    if (bcl.s.loading.props.defaultLoading) {
      bcl.ajax.getRequest(null, bcl.s.loading.props.defaultLoading, bcl.s.loading.saveLoading, null, null, null, false);
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-loadings.css", bcl.s.loading.props.dynamicClass);
  },

  loadNewLoader(url) {
    if (!url) {
      console.error("No URL provided for loading new loader");
      return;
    }

    bcl.ajax.getRequest(bcl.s.loading.props.loader, url);
  },

  saveLoading(request) {
    if (!request || !request.response) {
      console.error("Invalid request or response in saveLoading");
      return;
    }

    const $content = document.createElement("div");
    $content.innerHTML = request.response;
    bcl.s.loading.props.loader = $content.querySelector(`.${bcl.s.loading.props.cssClass}`);

    if (!bcl.s.loading.props.loader) {
      console.error(`Loader element with class ${bcl.s.loading.props.cssClass} not found`);
    }
  },
};

bcl.preInit.push(bcl.s.loading.init);
